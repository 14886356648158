











































































































































































































































































  .el-dialog--center .el-dialog__body {
    text-align: initial;
    padding: 20px 25px 30px;
}
