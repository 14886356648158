






























































































































































































































































































































































































































































































































































































































































































































































































.el-textarea {
  .el-textarea__inner {
    min-height: 10rem !important;
    resize: none;
  }
}
